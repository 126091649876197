import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import constants from "../../../Utils/constants";
import {
  haveValue,
  truncateString,
  skipMaccUser,
} from "../../../helper/common";
import TenantContext from "../../../context/tenant/TenantContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { toast } from "react-toastify";
import { VscEye } from "react-icons/vsc";
import Loader from "../../../components/Spinner";

const TenantProvideDocument = () => {
  // const clave_docs = new Map();
  const { _t } = useTranslator();
  const queryParameters = new URLSearchParams(window.location.search);
  const modelType = queryParameters.get("type");
  const waitingId = queryParameters.get("waiting");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const {
    uploadFileData,
    fetchTenantDocs,
    updateTenant,
    initiateBankFlip,
    fetchWaitingData,
  } = tenantContext;
  const { user } = authContext;
  const [fileType, setFileType] = useState("");
  const [show, setShow] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [tenantDocsData, setTenantDocsData] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isBankflip, setIsBankflip] = useState(false);
  const [currentStep, setCurrentStep] = useState(() => {
    return skipMaccUser(user?.user_type) ? 1 : 0;
  });
  const [revisit, setRevisit] = useState(false);
  const [fromClave, setFromClave] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [clave_docs, set_clave_docs] = useState(new Map());
  const [skipStep, setSkipStep] = useState(false);
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  const [claveModels, setClaveModels] = useState({
    AEAT_100_RENTA: false,
    AEAT_130_IRPF_DIRECTA: false,
    AEAT_303_IVA_AUTOLIQUIDACION: false,
    SEG_SOCIAL_VIDA_LABORAL: false,
  });

  useEffect(() => {
    fetchFileData();
    if (haveValue(modelType) && modelType == "manual") {
      setCurrentStep(1);
      setFromClave(false);
    }
    if (haveValue(waitingId)) {
      setLocalLoading(true);
      const temp_interval = setInterval(() => {
        fetchWaitingData(waitingId)
          .then((res) => {
            if (res.status == constants.COMPLETED) {
              clearInterval(temp_interval);
              fetchFileData();
              setLocalLoading(false);
            }
          })
          .catch((err) => {
            setLocalLoading(false);
            clearInterval(temp_interval);
            console.log(err);
          });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (user?.docs_uploaded) {
      setRevisit(true);
    }
    if (user?.employment_status == constants.employed) {
      setClaveModels((prev) => ({
        ...prev,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
      }));
    }
    if (user?.employment_status == constants.self_employed) {
      setClaveModels((prev) => ({
        ...prev,
        SEG_SOCIAL_VIDA_LABORAL: true,
        AEAT_100_RENTA: true,
        AEAT_130_IRPF_DIRECTA: true,
      }));
    }
    if (
      user?.employment_status == constants.self_employed &&
      user?.is_freelancer == true
    ) {
      setClaveModels((prev) => ({
        ...prev,
        AEAT_303_IVA_AUTOLIQUIDACION: true,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (tenantDocsData.length > 2) {
      console.log("CONDITION MET HERE");
      setSliderSettings({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        nextArrow: <BsFillArrowRightSquareFill />,
        prevArrow: <BsFillArrowLeftSquareFill />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
    if (haveValue(tenantDocsData) && tenantDocsData.length > 1) {
      const temp_map = new Map();
      tenantDocsData.map((docs) => {
        if (docs?.from == "clave") {
          console.log(docs?.type, "docs?.typedocs?.type");
          temp_map.set(docs?.type);
        }
      });
      set_clave_docs(temp_map);
    }
  }, [tenantDocsData]);

  useEffect(() => {
    if (user) {
      let res = skipMaccUser(user?.user_type) ? true : false;
      if (res === true && currentStep === 0) {
        setCurrentStep(1);
      }
      setSkipStep(res);
    }
  }, [user]);

  const navigateHome = () => {
    navigate("/tenant");
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
    if (
      e.target.value == constants.income_tax_return ||
      e.target.value == constants.fractioned_tax_return ||
      e.target.value == constants.quarterly_vat ||
      e.target.value == constants.previous_working_life_certificate
    ) {
      // checkBankflip(e.target.value);
      setIsBankflip(true);
    } else {
      setIsBankflip(false);
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("tenantDocs", files[i]);
    }
    formData.append("fileType", fileType);
    setFileData(formData);
  };

  const handleFileUpload = async () => {
    try {
      setUploadLoading(true);
      await uploadFileData(fileData);

      setFileData(null);
      setFileType("");
      await fetchFileData();
      handleClose();
      setUploadLoading(false);
    } catch (err) {
      setUploadLoading(false);
      console.log(err);
    }
  };

  // const addRewardPoints = () => {
  //   const data = {
  //     reward_points:
  //       (user?.reward_points || 0) + +constants.DOCS_COMPLETE_POINTS,
  //   };
  //   updateTenant(data);
  // };

  const fetchFileData = async () => {
    console.log("fetchFileData called");
    let userid = localStorage.getItem("saveduserid");
    const res = await fetchTenantDocs(userid);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleSubmitDocuments = async () => {
    // if (!user?.docs_uploaded) {
    //   addRewardPoints();
    // }
    // updateTenant({ docs_uploaded: true })
    //   .then((res) => {
    setCurrentStep(2);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  const checkNextButton = () => {
    if (!haveValue(user?.onfido_kyc_success) || !user?.onfido_kyc_success) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/verifyId")}
        >
          {_t("continueToVerifyId")}
        </button>
      );
    } else if (
      (!haveValue(user?.have_gurantor) ||
        !user?.have_gurantor ||
        user?.have_gurantor == "false") &&
      (!haveValue(user?.kyc_success) ||
        !user?.kyc_success ||
        user?.kyc_success == "false")
    ) {
      return (
        <button
          className="btn btnorange"
          onClick={(e) => navigate("/tenant/connectBank")}
        >
          {_t("continueToConnectBank")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const handleClaveChange = (e) => {
    const event = e.target;
    setClaveModels((prev) => ({ ...prev, [event.value]: event.checked }));
  };

  const checkBankflip = () => {
    // var temp = "";
    // const type = fileType;
    // if (type == constants.income_tax_return) {
    //   temp = "AEAT_100_RENTA";
    // } else if (type == constants.fractioned_tax_return) {
    //   temp = "AEAT_130_IRPF_DIRECTA";
    // } else if (type == constants.quarterly_vat) {
    //   temp = "AEAT_303_IVA_AUTOLIQUIDACION";
    // } else if (type == constants.previous_working_life_certificate) {
    //   temp = "SEG_SOCIAL_VIDA_LABORAL";
    // }

    if (
      !claveModels.AEAT_100_RENTA &&
      !claveModels.AEAT_130_IRPF_DIRECTA &&
      !claveModels.AEAT_303_IVA_AUTOLIQUIDACION &&
      !claveModels.SEG_SOCIAL_VIDA_LABORAL
    ) {
      toast.error(_t("selectAtLeastOneDocument"));
      return;
    }
    initiateBankFlip(claveModels)
      .then((res) => {
        window.location.replace(res.success_res.widgetLink);
        console.log(res, "BANK FLIP RESPONSE");
      })
      .catch((err) => {
        console.log(err);
        toast.error(_t("errorWhileInitiatingTheSignatureProcess"));
      });
  };

  const createOption = (certificateType) => {
    let element = "";
    let cloveHasDoc = clave_docs.has(certificateType);

    switch (certificateType) {
      case constants.previous_working_life_certificate:
        element = (
          <option
            value={constants.previous_working_life_certificate}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("workingLifeCertificate")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      case constants.income_tax_return:
        element = (
          <option
            value={constants.income_tax_return}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("lastAnnualTaxReportModel100")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      case constants.fractioned_tax_return:
        element = (
          <option
            value={constants.fractioned_tax_return}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("lastFractionatedTaxReportModel130")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      case constants.quarterly_vat:
        element = (
          <option
            value={constants.quarterly_vat}
            disabled={cloveHasDoc} // Disable the option if cloveHasDoc is true
          >
            {_t("lastQuarterlyVATTaxForm303")}&nbsp;
            {cloveHasDoc && <span>&#10003;</span>}
          </option>
        );
        break;
      default:
        element = "";
    }
    return element;
  };

  return (
    <>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={navigateHome}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              <h3 className="step-form-name mobile-model-heading">
                <img src="/images/document-home-icon.svg" alt="" />
                <span>{_t("provideDocuments")}</span>
              </h3>
            </div>
            {!skipStep && (
              <div className="logobarwrapper">
                <Container className="progress-bar">
                  <ul className="progress-ul">
                    <li
                      className={`progress-li ${
                        currentStep == 0 ? "current-progress" : ""
                      }  ${currentStep > 0 ? "current-completed" : ""}`}
                    ></li>
                    <li
                      className={`progress-li ${
                        currentStep == 1 ? "current-progress" : ""
                      }  ${currentStep > 1 ? "current-completed" : ""}`}
                    ></li>
                  </ul>
                </Container>
              </div>
            )}
            <div className="steps-wrapper">
              <h3 className="step-name">{_t("selectAnOptionToContinue")}</h3>
              <div className="resend-text steps-wrapper cust-width-setting">
                <div className="cust-inner-wrapper">
                  <div className="">
                    <div className="icon-text-box">
                      <img
                        width="30px"
                        height="30px"
                        src="/images/clavePin.svg"
                        alt=""
                      />
                      <div className="text-box">
                        <h4
                          className="box-heading"
                          style={{ textTransform: "none" }}
                        >
                          {_t("step1DownloadWithClavePIN")}
                        </h4>
                        <p
                          className="box-desc"
                          style={{ textTransform: "none" }}
                        >
                          {_t(
                            "useClavePINToEasilyAndSecurelyDownloadYourDocumentsWorkingLifeCertificateTaxReportOrContributionReportThisIsTheSimplestAndMostEfficientWayToGetYourInformationAutomatically"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="icon-text-box">
                      <img
                        width="30px"
                        height="30px"
                        src="/images/uploadIcon.svg"
                        alt=""
                      />
                      <div className="text-box">
                        <h4 className="box-heading">
                          {_t("step2UploadManually")}
                        </h4>
                        <p
                          className="box-desc"
                          style={{ textTransform: "none" }}
                        >
                          {_t(
                            "ifYouDontHaveAClavePINYouCanManuallyUploadYourDocumentsWeWillVerifyThemForAccuracySoPleaseEnsureThatAllDocumentsAreUpToDate"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btnorange step-btn"
                    onClick={(e) => {
                      setFromClave(true);
                      setCurrentStep((prev) => prev + 1);
                    }}
                    style={{ textTransform: "none" }}
                  >
                    {_t("downloadWithClavePIN")}
                  </button>
                  <button
                    className="btn btnoutline bg-none mt-3"
                    onClick={(e) => {
                      setFromClave(false);
                      setCurrentStep((prev) => prev + 1);
                    }}
                  >
                    {_t("uploadManually")}
                  </button>
                </div>
                <div className="document_preview_div">
                  <h5>{_t("yourDocuments")}</h5>
                  {localLoading ? (
                    <Loader
                      waiting_text={
                        "Fetching your documents. It may take some time."
                      }
                    />
                  ) : tenantDocsData.length == 0 ? (
                    _t("noDocumentsUploaded")
                  ) : (
                    <Row
                      className="card-listing scwdfwe"
                      // style={{ maxWidth: "fit-content", margin: "0 auto" }}
                    >
                      <Slider {...sliderSettings}>
                        {tenantDocsData?.map((eachData, index) => (
                          <Col md={6} key={eachData?._id}>
                            <Card>
                              <div className="videoframe">
                                <iframe
                                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                                  scrolling="no"
                                />
                              </div>
                              <Card.Body>
                                <div className="listing-head">
                                  <div className="head-block">
                                    <div
                                      className="leftbartext"
                                      style={{ maxWidth: "85%" }}
                                    >
                                      <h5 style={{ paddingTop: "0px" }}>
                                        {_t(eachData?.type, "fixed")}
                                      </h5>
                                      <p style={{ overflow: "hidden" }}>
                                        {truncateString(
                                          eachData?.file_name
                                            .split("-")
                                            .slice(1)
                                            .join("-"),
                                          60
                                        )}
                                      </p>
                                    </div>
                                    <VscEye
                                      onClick={(e) =>
                                        handlePreviewDocument(
                                          `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                              {eachData?.from == "clave" ? (
                                // <img
                                //   className="upload_doc_icons"
                                //   src="/images/downloaded_clave.svg"
                                //   alt=""
                                // />
                                <label className="doc-uploaded-from">
                                  {_t("clavePin")}
                                </label>
                              ) : (
                                <label className="doc-uploaded-from">
                                  {_t("uploaded")}
                                </label>
                                // <img
                                //   className="upload_doc_icons"
                                //   src="/images/downloaded_upload.svg"
                                //   alt=""
                                // />
                              )}
                            </Card>
                          </Col>
                        ))}
                      </Slider>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a
                    href={undefined}
                    onClick={(e) => {
                      if (skipStep) navigateHome();
                      else setCurrentStep((prev) => prev - 1);
                    }}
                  >
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              <h3 className="step-form-name">
                <img src="/images/document-home-icon.svg" alt="" />
                <span className="set-width-for-mob">
                  {_t("provideDocuments")}
                </span>
              </h3>
            </div>
            {!skipStep && (
              <div className="logobarwrapper">
                <Container className="progress-bar">
                  <ul className="progress-ul">
                    <li
                      className={`progress-li ${
                        currentStep == 0 ? "current-progress" : ""
                      }  ${currentStep > 0 ? "current-completed" : ""}`}
                    ></li>
                    <li
                      className={`progress-li ${
                        currentStep == 1 ? "current-progress" : ""
                      }  ${currentStep > 1 ? "current-completed" : ""}`}
                    ></li>
                  </ul>
                </Container>
              </div>
            )}
            {fromClave ? (
              <div className="steps-wrapper">
                <h3 className="step-name" style={{ textTransform: "none" }}>
                  {_t("selectDocumentsToGetFromClavePIN")}
                </h3>
                <div className="align-items-center clave-checboxes">
                  {(user?.employment_status == constants.employed ||
                    user?.employment_status == constants.self_employed) && (
                    <label class="customradiobar forminput">
                      <input
                        type="checkbox"
                        name="have_sharer"
                        className="clave_checkbox"
                        onClick={handleClaveChange}
                        checked={claveModels.AEAT_100_RENTA}
                        value={"AEAT_100_RENTA"}
                      />
                      <div
                        class="boxes"
                        style={{ border: "2px solid #253837" }}
                      >
                        <span className="empty_checkbox"></span>
                        <img src="/images/greenCheck.svg" alt="" />
                        {_t("lastAnnualTaxReportModel100")}
                      </div>
                    </label>
                  )}
                  {user?.employment_status == constants.self_employed && (
                    <label class="customradiobar forminput">
                      <input
                        type="checkbox"
                        name="have_sharer"
                        className="clave_checkbox"
                        onClick={handleClaveChange}
                        checked={claveModels.AEAT_130_IRPF_DIRECTA}
                        value={"AEAT_130_IRPF_DIRECTA"}
                      />
                      <div
                        class="boxes"
                        style={{ border: "2px solid #253837" }}
                      >
                        <span className="empty_checkbox"></span>
                        <img src="/images/greenCheck.svg" alt="" />
                        {_t("lastFractionatedTaxReportModel130")}
                      </div>
                    </label>
                  )}
                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer == true && (
                      <label class="customradiobar forminput">
                        <input
                          type="checkbox"
                          name="have_sharer"
                          className="clave_checkbox"
                          onClick={handleClaveChange}
                          checked={claveModels.AEAT_303_IVA_AUTOLIQUIDACION}
                          value={"AEAT_303_IVA_AUTOLIQUIDACION"}
                        />
                        <div
                          class="boxes"
                          style={{ border: "2px solid #253837" }}
                        >
                          <span className="empty_checkbox"></span>
                          <img src="/images/greenCheck.svg" alt="" />
                          {_t("lastQuarterlyVATTaxForm303")}
                        </div>
                      </label>
                    )}
                  {(user?.employment_status == constants.employed ||
                    user?.employment_status == constants.self_employed) && (
                    <label class="customradiobar forminput">
                      <input
                        type="checkbox"
                        name="have_sharer"
                        className="clave_checkbox"
                        onClick={handleClaveChange}
                        checked={claveModels.SEG_SOCIAL_VIDA_LABORAL}
                        value={"SEG_SOCIAL_VIDA_LABORAL"}
                      />
                      <div
                        class="boxes"
                        style={{ border: "2px solid #253837" }}
                      >
                        <span className="empty_checkbox"></span>
                        <img src="/images/greenCheck.svg" alt="" />
                        {_t("workingLifeCertificate")}
                      </div>
                    </label>
                  )}
                </div>
                {/* {!revisit && ( */}
                <button
                  className="btn btnorange step-btn"
                  onClick={checkBankflip}
                >
                  {_t("next")}
                </button>
                {/* )} */}
              </div>
            ) : (
              <div className="steps-wrapper cust-width-setting">
                <div className="cust-inner-wrapper">
                  <h3 className="step-name">{_t("uploadDocumentsBelow")}</h3>
                  <div className="align-items-center">
                    <Form.Select
                      size="lg"
                      onChange={(e) => {
                        handleSelectChange(e);
                      }}
                      value={fileType}
                      className="form-control"
                    >
                      <option value="">{_t("select")}</option>

                      {user?.employment_status == constants.student && (
                        <>
                          <option value={constants.ie_student}>
                            {_t("studentRegistrationContract")}
                          </option>
                          <option
                            value={constants.university_acceptance_letter}
                          >
                            {_t("universityAcceptanceLetter")}
                          </option>
                        </>
                      )}

                      {user?.employment_status == constants.employed && (
                        <>
                          <option value={constants.employment_contract}>
                            {_t("employmentContract")}
                          </option>
                          <option value={constants.payslips}>
                            {_t("lastThreePayslips")}
                          </option>
                          {createOption(
                            constants.previous_working_life_certificate
                          )}
                          {createOption(constants.income_tax_return)}
                          <option value={constants.deduction_certificate}>
                            {_t("deductionCertificate")}
                          </option>
                          <option
                            value={constants.public_appointment_certificate}
                          >
                            {_t(
                              "publicAppointmentCertificateOnlyIfPublicEmployee"
                            )}
                          </option>
                        </>
                      )}

                      {user?.employment_status == constants.self_employed &&
                        user?.is_freelancer == true && (
                          <>
                            {createOption(constants.fractioned_tax_return)}
                            {createOption(constants.income_tax_return)}
                            {createOption(
                              constants.previous_working_life_certificate
                            )}
                            {createOption(constants.quarterly_vat)}
                          </>
                        )}

                      {user?.employment_status == constants.self_employed &&
                        user?.is_freelancer != true && (
                          <>
                            {createOption(
                              constants.previous_working_life_certificate
                            )}
                            {createOption(constants.income_tax_return)}
                            {createOption(constants.fractioned_tax_return)}
                            <option value={constants.corporate_tax_return}>
                              {_t("lastYearsCorporateTaxReturn")}
                            </option>
                            <option value={constants.corporate_balance_sheet}>
                              {_t("lastYearsCorporateBalanceSheet")}
                            </option>
                            <option value={constants.corporate_PL}>
                              {_t("lastYearsCorporatePAndL")}
                            </option>
                          </>
                        )}
                      {user?.employment_status == constants.retired && (
                        <>
                          <option value={constants.pension_certificate}>
                            {_t("pensionCertificate")}
                          </option>
                          <option value={constants.last_revalorization_letter}>
                            {_t("lastRevalorizationLetter")}
                          </option>
                        </>
                      )}
                      {
                        <option value="Other Documents">
                          {_t("otherDocuments")}
                        </option>
                      }
                    </Form.Select>
                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="btn step-go-home-btn btn-100 mt-3"
                        onClick={() => {
                          handleShow();
                        }}
                        disabled={haveValue(fileType) ? false : true}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none"
                        >
                          <path
                            d="M0 18H16V15.8824H0V18ZM0 7.41177H4.57143V13.7647H11.4286V7.41177H16L8 0L0 7.41177Z"
                            fill="#253837"
                          />
                        </svg>
                        {_t("uplaodHere")}
                      </button>
                    </div>
                  </div>
                </div>
                <Row className="card-listing">
                  <Slider {...sliderSettings}>
                    {tenantDocsData?.map((eachData, index) => (
                      <Col md={6} key={eachData?._id}>
                        <Card>
                          <div className="videoframe">
                            <iframe
                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                              scrolling="no"
                            />
                          </div>
                          <Card.Body>
                            <div className="listing-head">
                              <div className="head-block">
                                <div
                                  className="leftbartext"
                                  style={{ maxWidth: "85%" }}
                                >
                                  <h5 style={{ paddingTop: "0px" }}>
                                    {console.log(
                                      eachData.type,
                                      "check data here"
                                    )}
                                    {_t(eachData?.type, "fixed")}
                                  </h5>
                                  <p style={{ overflow: "hidden" }}>
                                    {truncateString(
                                      eachData?.file_name
                                        .split("-")
                                        .slice(1)
                                        .join("-"),
                                      60
                                    )}
                                  </p>
                                </div>
                                <VscEye
                                  onClick={(e) =>
                                    handlePreviewDocument(
                                      `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </Card.Body>
                          {eachData?.from == "clave" ? (
                            // <img
                            //   className="upload_doc_icons"
                            //   src="/images/downloaded_clave.svg"
                            //   alt=""
                            // />
                            <label className="doc-uploaded-from">
                              {_t("clavePin")}
                            </label>
                          ) : (
                            <label className="doc-uploaded-from">
                              {_t("uploaded")}
                            </label>
                            // <img
                            //   className="upload_doc_icons"
                            //   src="/images/downloaded_upload.svg"
                            //   alt=""
                            // />
                          )}
                        </Card>
                      </Col>
                    ))}
                  </Slider>
                </Row>
                <div className="cust-inner-wrapper ">
                  <div className="d-flex gap-2">
                    {!revisit && (
                      <button
                        className="btn btnorange step-btn"
                        disabled={tenantDocsData.length < 1}
                        onClick={handleSubmitDocuments}
                      >
                        {_t("submit")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <Modal
              className="modal-uploadpdf upload-doc"
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">{_t("selectDocument")}</h5>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formFile" className="formcontrolbar">
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    multiple={
                      fileType != constants.income_tax_return &&
                      fileType != constants.ie_student
                    }
                    accept="application/pdf"
                  />
                  <Button
                    className="btn-green"
                    onClick={handleFileUpload}
                    disabled={
                      haveValue(fileData) && !uploadLoading ? false : true
                    }
                  >
                    {uploadLoading ? _t("pleaseWait") : _t("upload")}
                  </Button>
                </Form.Group>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
      {currentStep == 2 && (
        <>
          <div className="step-form-main-wrapper">
            <div class="d-flex justify-content-center align-items-center mb-5">
              <h3 className="step-form-name">
                <img src="/images/document-home-icon.svg" alt="" />
                <span>{_t("provideDocuments")}</span>
              </h3>
            </div>
            <div className="step-complete-reward steps-wrapper">
              <div className="rewards-points">
                <span className="points">50</span>
                <p>{_t("rewardPoints")}</p>
              </div>
              <p className="greeting-text">{_t("congratulations")}</p>
              <p className="points-info">
                {_t(
                  "youHaveUploadedYourDocumentationToYourProfileInfoAndHaveBeenRewardedWithFiftyPoints"
                )}
              </p>
              {checkNextButton()}
              <button className="step-go-home-btn" onClick={navigateHome}>
                {_t("goBackHome")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantProvideDocument;
