import { useContext, useEffect, useState } from "react";
import LandLordContext from "../../context/landlord/LandLordContext";
import { useNavigate, useParams } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { haveValue } from "../../helper/common";
import { toast } from "react-toastify";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FaRegHourglass } from "react-icons/fa";
import constants from "../../Utils/constants";
import { TiTick } from "react-icons/ti";
import "../../assets/css/payment.css";
import AuthContext from "../../context/auth/AuthContext";
import { Modal, Card } from "react-bootstrap";
const PaymentDetails = () => {
  const { _t } = useTranslator();
  const landLordContext = useContext(LandLordContext);
  const { fetchAssignedDetails, loadRentLeger, endLease } = landLordContext;
  const authContext = useContext(AuthContext);
  const { setLoading, user } = authContext;
  const { assingedPropId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [upcomingDate, setUpcomingDate] = useState(new Date());
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [dueAmount, setDueAmount] = useState(0);
  const [depositHistory, setDepositHistory] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (haveValue(assingedPropId)) {
      fetchAssignedDetails(assingedPropId)
        .then((res) => {
          setData(res.result);
          setLoading(false);
          var temp_data = new Date();
          var lease_start = new Date(res.result.payment_start);
          if (temp_data < lease_start) {
            temp_data = lease_start;
          }
          var check = new Date(
            temp_data.getFullYear(),
            temp_data.getMonth(),
            res.result?.payment_date
          );
          if (check < temp_data) {
            check = new Date(
              new Date(temp_data).getFullYear() +
                (new Date(temp_data).getMonth() === 11 ? 1 : 0),
              (new Date(temp_data).getMonth() + 1) % 12,
              res.result?.payment_date
            );
          }
          setUpcomingDate(check);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(_t("errorWhileFetchingDetails"));
        });
      loadRentLeger(assingedPropId)
        .then((res) => {
          const data = res.result;

          var temp = 0;
          data.map((val) => {
            if (val?.status == constants.PENDING) {
              temp += +val?.amount;
            }

            if (val?.type == constants.DEPOSIT) {
              setDepositHistory(val);
            }
          });
          setDueAmount(temp);
          setPaymentHistory(data);
        })
        .catch((err) => {
          console.log(err);
          // toast.error(_t("Error while fetching details"));
        });
    }
  }, []);

  const handleEditDetails = () => {
    navigate(
      `/assignProperty/${data?.property_id?._id}?step=1&sharedProfile=${data?.shared_profile_id?._id}&editId=${data?._id}`
    );
  };
  const handleEndLease = (assignPropId) => {
    endLease(assignPropId)
      .then((res) => {
        toast.success(_t("leaseEnded"));
        navigate("/payment/property/list");
      })
      .catch((err) => {
        toast.error(_t("errorWhileEndingLease"));
        console.log(err);
      });
  };

  return (
    <>
      {haveValue(data) ? (
        <>
          <div className="payment-detail-page">
            <div className="back-header">
              <a
                href={undefined}
                onClick={(e) => navigate("/payment/property/list")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                >
                  <path
                    d="M9 1L1 9L9 17"
                    stroke="#121212"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 className="detail-page-heading">{`${
                haveValue(data?.tenant_id?.fname) ? data?.tenant_id?.fname : ""
              } ${
                haveValue(data?.tenant_id?.lname) ? data?.tenant_id?.lname : ""
              }`}</h1>
            </div>
            <div className="detail-body">
              <h2 className="payment-text"> {_t("payments")} </h2>

              {data?.status == constants.TENANT_PENDING && (
                <div className="payment-detail-content">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.712 6.72339C13.3207 7.09299 12.85 7.27778 12.3 7.27778C11.7473 7.2803 11.2763 7.0955 10.887 6.72339C10.4977 6.35128 10.302 5.90645 10.3 5.38889C10.298 4.87134 10.4937 4.42682 10.887 4.05534C11.2803 3.68386 11.7513 3.49875 12.3 3.50001C12.8487 3.50127 13.3193 3.68638 13.712 4.05534C14.1047 4.4243 14.3007 4.86882 14.3 5.38889C14.2993 5.90897 14.1033 6.3538 13.712 6.72339ZM13.8 9.16667V20.5H10.8V9.16667H13.8Z"
                        fill="#07569E"
                      ></path>
                    </svg>{" "}
                    <span> {_t("waitingForTenantToSetUpPayment")}</span>
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-between gap-4 mobile-custom">
                <div className="upcoming-side">
                  <div className="upcoming-box">
                    <h4 className="upcoming-text"> {_t("upcoming")} </h4>
                    <table className="upcoming-table">
                      <thead>
                        <tr className="first-row">
                          <th>{_t("date")}</th>
                          <th>{_t("subject")}</th>
                          <th>{_t("amount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="second-row">
                          <td>
                            {new Date(upcomingDate).toLocaleDateString("en-GB")}
                          </td>
                          <td>
                            <p>{_t("rent")}</p>
                          </td>
                          <td>
                            {data?.deposit_type == constants.SKOR_DEPOSIT
                              ? +data?.rent_amount + +data?.monthly_sub_amount
                              : data?.rent_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="upcoming-box">
                    <h4 className="upcoming-text"> {_t("history")} </h4>
                    <table className="upcoming-table">
                      <thead>
                        <tr className="first-row">
                          <th> {_t("date")} </th>
                          <th> {_t("subject")} </th>
                          <th> {_t("amount")} </th>
                          <th> {_t("status")} </th>
                        </tr>
                      </thead>

                      <tbody>
                        {haveValue(paymentHistory) && paymentHistory.length > 0
                          ? paymentHistory.map((val) => (
                              <tr className="second-row">
                                <td>
                                  {new Date(
                                    val?.processing_date
                                  ).toLocaleDateString("en-GB")}
                                </td>
                                <td>
                                  {_t(val?.type)}
                                  <p>
                                    {user?.rental_data?.type ==
                                    constants.RENTAL_INFO
                                      ? ``
                                      : ``}
                                  </p>
                                </td>
                                <td>{_t(val?.amount)}</td>
                                <td>
                                  {val?.type == constants.DEPOSIT &&
                                  haveValue(data?.deposit_slip)
                                    ? _t("proofUploaded")
                                    : _t(val?.status)}
                                </td>
                              </tr>
                            ))
                          : _t("noDetailsFound")}
                      </tbody>
                    </table>
                  </div>
                  <div className="upcoming-box">
                    <h4 className="upcoming-text">
                      {" "}
                      {_t("fullDepositDetails")}{" "}
                    </h4>
                    <table className="upcoming-table">
                      <thead>
                        <tr className="first-row">
                          <th> {_t("amount")} </th>
                          <th> {_t("paidOn")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="second-row">
                          <td className="paidamount-first">
                            {data?.deposit_type == constants.SKOR_DEPOSIT
                              ? data?.rent_amount
                              : data?.deposit_amount}{" "}
                            €
                          </td>
                          <td>
                            {depositHistory
                              ? new Date(
                                  depositHistory?.processing_date
                                ).toLocaleDateString("en-GB")
                              : new Date().toLocaleDateString("en-GB")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {haveValue(data?.deposit_slip) && (
                      <div className="upload-document">
                        <p>{_t("document")}</p>
                        <Card className="document-card">
                          <div className="videoframe">
                            <iframe
                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${data?.deposit_slip}`}
                              scrolling="no"
                            />
                          </div>
                          <Card.Body>
                            <div className="listing-head">
                              <div className="head-block">
                                <div className="leftbartext">
                                  <p className="pdf-or-else">
                                    {data?.deposit_slip
                                      .split("-")
                                      .slice(1)
                                      .join("-")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
                <div className="current-side">
                  <div className="current-upper">
                    <h5> {_t("currentBalanceDue")} </h5>
                    <h3> {dueAmount} </h3>
                    <div className="waiting-tenant">
                      {data?.status == constants.TENANT_PENDING && (
                        <>
                          <h4>
                            <FaRegHourglass />{" "}
                            <span>{_t("waitingForTenant")}</span>
                          </h4>
                        </>
                      )}
                      {data?.status == constants.AWARDED && (
                        <>
                          <h4>
                            <TiTick /> <span>{_t("inPayment")}</span>
                          </h4>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="current-down">
                    <h4> {_t("leaseInformation")} </h4>
                    <div>
                      <h6>{_t("moveInAddress")}</h6>
                      <p>
                        {`${
                          haveValue(data?.landlord_id?.fname_companyName)
                            ? data?.landlord_id?.fname_companyName
                            : ""
                        } ${
                          haveValue(data?.landlord_id?.lname)
                            ? data?.landlord_id?.lname
                            : ""
                        }`}
                      </p>
                      <p>
                        {`${data?.property_id?.street} - ${data?.property_id?.street_number}, ${data?.property_id?.floor_number}-${data?.property_id?.flat_number}`}{" "}
                      </p>
                    </div>
                    <div className="row ">
                      <div className="col-sm-6 custom-gap">
                        <h6> {_t("leaseStartDate")} </h6>
                        <p>
                          {" "}
                          {new Date(data?.payment_start).toLocaleDateString(
                            "en-GB"
                          )}
                        </p>
                      </div>
                      <div className="col-sm-6 custom-gap">
                        <h6> {_t("leaseEndDate")} </h6>
                        <p>
                          {" "}
                          {haveValue(data?.lease_end)
                            ? new Date(data?.lease_end).toLocaleDateString(
                                "en-GB"
                              )
                            : _t("open")}
                        </p>
                      </div>
                      <div className="col-sm-6 custom-gap">
                        <h6> {_t("rent")} </h6>
                        <p> {data?.rent_amount} </p>
                      </div>
                      <div className="col-sm-6 custom-gap">
                        <h6> {_t("deposit")} </h6>
                        <p> {data?.deposit_amount} </p>
                      </div>
                    </div>
                    {data?.status == constants.TENANT_PENDING && (
                      <button
                        className="edit-det-bt"
                        onClick={handleEditDetails}
                      >
                        {_t("editDetails")}
                      </button>
                    )}
                    {/* {data?.status == constants.AWARDED && ( */}
                    <button
                      className="edit-det-bt"
                      onClick={(e) => setConfirmPopup(true)}
                    >
                      {_t("endRental")}
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h4>{_t("noDetails")}</h4>
      )}
      <Modal
        show={confirmPopup}
        className="metermodal"
        centered
        onHide={(e) => setConfirmPopup(false)}
      >
        <Modal.Body>
          <h4>
            {_t(
              "areYouSureYouWantToEndRentalPleaseNoteThatThisActionCannotBeUndone"
            )}
          </h4>
          <div className="auth-form">
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={(e) => {
                  setConfirmPopup(false);
                }}
              >
                {_t("no")}
              </button>
              <button
                className="btn btngreen"
                onClick={(e) => handleEndLease(data?._id)}
              >
                {_t("yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentDetails;
