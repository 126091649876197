import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BsExclamationCircleFill } from "react-icons/bs";
import { FaRegHourglass } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import constants from "../../../Utils/constants";
import { haveValue } from "../../../helper/common";
import useTranslator from "../../../hooks/useTranslator";
import LandLordContext from "../../../context/landlord/LandLordContext";
import AuthContext from "../../../context/auth/AuthContext";
import { Card } from "react-bootstrap";
import TenantContext from "../../../context/tenant/TenantContext";
import moment from "moment";
import Loader from "../../../components/Spinner";

const PropertyPayment = () => {
  const { _t } = useTranslator();
  const landLordContext = useContext(LandLordContext);
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { ledgerData, getRentalInfo } = tenantContext;
  const { fetchAssignedDetails, loadRentLeger } = landLordContext;
  const { setLoading, user } = authContext;
  const { assignedPropId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const confirmation = queryParams.get("confirmation");
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [upcomingDate, setUpcomingDate] = useState(new Date());
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [depositHistory, setDepositHistory] = useState(null);
  const [dueAmount, setDueAmount] = useState(0);
  const [showNoti, setShowNoti] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  if (haveValue(confirmation) && confirmation) {
    navigate(`/awarded/property/${assignedPropId}`);
    setTimeout(() => {
      setShowNoti(true);
      setTimeout(() => {
        setShowNoti(false);
      }, 10000);
    }, 100);
  }

  useEffect(() => {
    setLoading(true);
    if (user?.rental_data?.type == constants.RENTAL_INFO) {
      getRentalInfo()
        .then((res) => {
          console.log("DATA CAME INSIDE", res.success_res);
          const temp = { ...res.success_res };
          temp.property_id = {
            street: res.success_res.street,
            street_number: res.success_res.street_number,
            floor_number: res.success_res.floor_number,
            flat_number: res.success_res.flat_number,
          };
          setData(temp);
          setLoading(false);
          var temp_data = new Date();
          var lease_start = new Date(res?.success_res?.rent_date);
          if (temp_data < lease_start) {
            temp_data = lease_start;
          }
          var check = new Date(
            temp_data.getFullYear(),
            temp_data.getMonth(),
            moment(res.success_res?.rent_date).date()
          );
          console.log(check, temp_data, "check 1st date");
          if (check < temp_data) {
            check = new Date(
              new Date(temp_data).getFullYear() +
                (new Date(temp_data).getMonth() === 11 ? 1 : 0),
              (new Date(temp_data).getMonth() + 1) % 12,
              moment(res.success_res?.rent_date).date()
            );
          }
          console.log(check, "2nd date");
          setUpcomingDate(check);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(_t("errorWhileFetchingDetails"));
        });
    } else if (user?.rental_data?.type == constants.ASSIGNED_PROPERTY) {
      if (haveValue(user?.rental_data?.id)) {
        fetchAssignedDetails(user?.rental_data?.id)
          .then((res) => {
            console.log("DATA CAME INSIDE", res.result);
            setData(res.result);
            setLoading(false);
            var temp_data = new Date();
            var lease_start = new Date(res.result.payment_start);
            if (temp_data < lease_start) {
              temp_data = lease_start;
            }
            var check = new Date(
              temp_data.getFullYear(),
              temp_data.getMonth(),
              res.result?.payment_date
            );

            if (check < temp_data) {
              check = new Date(
                new Date(temp_data).getFullYear() +
                  (new Date(temp_data).getMonth() === 11 ? 1 : 0),
                (new Date(temp_data).getMonth() + 1) % 12,
                res.result?.payment_date
              );
            }
            console.log(check, "2nd date");
            setUpcomingDate(check);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error(_t("errorWhileFetchingDetails"));
          });
        // loadRentLeger(assignedPropId)
        //   .then((res) => {
        //     const data = res.result;

        //     var temp = 0;
        //     data.map((val) => {
        //       console.log("STATUS HERE", val?.status);
        //       if (val?.status == constants.PENDING) {
        //         temp += +val?.amount;
        //       }
        //       if (val?.type == constants.DEPOSIT) {
        //         setDepositHistory(val);
        //       }
        //     });
        //     setDueAmount(temp);
        //     setPaymentHistory(data);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     toast.error(_t("Error while fetching details"));
        //   });
      }
    }
    setLocalLoading(true);
    ledgerData()
      .then((res) => {
        const data = res.success_res;
        console.log("INSIDE LEDGER DATA", data);
        setLoading(false);
        var temp = 0;
        var checkDeposit = false;
        data.map((val) => {
          // if (val?.status == constants.PENDING) {
          //   temp += +val?.amount;
          // }
          if (val?.type == constants.DEPOSIT && !checkDeposit) {
            if (val?.status == constants.PENDING) {
              navigate(
                `/accept/assigned/property/${val?.assigned_property_id?._id}`
              );
            }
            // console.log("check val here", val);
            checkDeposit = true;
            setDepositHistory(val);
          }
        });
        const temp_data = data.filter(
          (val) => val.status == constants.COMPLETED
        );
        setDueAmount(temp);
        // setPaymentHistory(data);
        setPaymentHistory(temp_data);
        setLocalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLocalLoading(false);
        toast.error(_t("errorWhileFetchingDetails"));
      });
  }, []);

  const navigateRevisitPayment = () => {
    navigate("/tenant/rentalStep?revisit=true");
  };

  console.log(data, "datadatadata");

  return (
    <>
      {localLoading ? (
        <Loader />
      ) : haveValue(data) ||
        (haveValue(paymentHistory) && paymentHistory.length > 0) ? (
        <>
          {showNoti && (
            <div class="tenant-review-content">
              <div class="row align-items-center">
                <div class="col-lg-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="77"
                    height="82"
                    viewBox="0 0 77 82"
                    fill="none"
                  >
                    <path
                      d="M38.4999 2.66797L48.5681 10.0126L61.0322 9.98964L64.8598 21.85L74.9568 29.1563L71.0832 41.0013L74.9568 52.8463L64.8598 60.1526L61.0322 72.013L48.5681 71.99L38.4999 79.3346L28.4316 71.99L15.9676 72.013L12.14 60.1526L2.04297 52.8463L5.91655 41.0013L2.04297 29.1563L12.14 21.85L15.9676 9.98964L28.4316 10.0126L38.4999 2.66797Z"
                      fill="#223637"
                      stroke="#223637"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M31.3009 47.9764L24.4034 41.1495C23.9187 40.6697 23.2623 40.4011 22.5789 40.4011C21.8956 40.4011 21.2392 40.6697 20.7544 41.1495C20.2695 41.6295 19.9961 42.2816 19.9961 42.9626C19.9961 43.2997 20.0632 43.6334 20.1934 43.9445C20.3236 44.2557 20.5143 44.538 20.7544 44.7757L29.4866 53.4185C30.4962 54.4178 32.1261 54.4178 33.1357 53.4185L55.2378 31.5426C55.7227 31.0626 55.9961 30.4105 55.9961 29.7295C55.9961 29.0485 55.7227 28.3964 55.2378 27.9164C54.753 27.4366 54.0966 27.168 53.4133 27.168C52.73 27.168 52.0737 27.4365 51.5889 27.9162C51.5889 27.9163 51.5888 27.9163 51.5888 27.9164L31.3009 47.9764Z"
                      fill="#A3EB64"
                      stroke="#A3EB64"
                    />
                  </svg>
                </div>
                <div class="col-lg-10">
                  <div class="review-content">
                    <h4>{_t("yourPaymentIsSet")}</h4>
                    <p>
                      {_t("thePaymentStartsOnThe")}{" "}
                      {new Date(upcomingDate).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                </div>
              </div>
              <div class="close-btn">
                <a href={undefined} onClick={(e) => setShowNoti(false)}>
                  {_t("close")}
                </a>
              </div>
            </div>
          )}

          <div className="d-block d-md-none">
            <div>
              {haveValue(data) && (
                <div className="lease-information mobile p-3 mb-3">
                  <h3 className="border-bottom pb-3 mb-3 fw-bold">
                    {" "}
                    {_t("leaseInformation")}{" "}
                  </h3>
                  <div className="mb-3">
                    <h6 className="text-muted mb-0">{_t("moveInAddress")}</h6>
                    <p className="fw-bold">
                      {" "}
                      {`${data?.property_id?.street} - ${data?.property_id?.street_number}, ${data?.property_id?.floor_number}-${data?.property_id?.flat_number}`}{" "}
                    </p>
                  </div>
                  <div className="row">
                    {user?.rental_data.type == constants.ASSIGNED_PROPERTY && (
                      <>
                        <div className="col-6 mb-3">
                          <h6 className="text-muted mb-0">
                            {" "}
                            {_t("leaseStartDate")}{" "}
                          </h6>
                          <p className="fw-bold">
                            {" "}
                            {new Date(data?.payment_start).toLocaleDateString(
                              "en-GB"
                            )}
                          </p>
                        </div>
                        <div className="col-6">
                          <h6 className="text-muted mb-0">
                            {" "}
                            {_t("leaseEndDate")}{" "}
                          </h6>
                          <p className="fw-bold">
                            {" "}
                            {haveValue(data?.lease_end)
                              ? new Date(data?.lease_end).toLocaleDateString(
                                  "en-GB"
                                )
                              : _t("open")}
                          </p>
                        </div>
                      </>
                    )}
                    <div className="col-6">
                      <h6 className="text-muted mb-0"> {_t("rent")} </h6>
                      <p className="fw-bold"> {data?.rent_amount} &#8364;</p>
                    </div>
                    {user?.rental_data.type == constants.ASSIGNED_PROPERTY && (
                      <div className="col-6">
                        <h6 className="text-muted mb-0"> {_t("deposit")} </h6>
                        <p className="fw-bold">
                          {" "}
                          {data?.deposit_amount} &#8364;
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="payment-information mobile p-3">
              <h2 className="fw-bold"> {_t("payments")} </h2>

              <div className="payment-box border p-3 mb-3">
                <h5 className="pb-3 border-bottom text-muted">
                  {" "}
                  {_t("upcomingRent")}{" "}
                </h5>
                <div className="d-flex justify-content-between">
                  <p>{new Date(upcomingDate).toLocaleDateString("en-GB")}</p>
                  <p className="price">
                    &#8364;
                    {data?.deposit_type == constants.SKOR_DEPOSIT
                      ? +data?.rent_amount + +data?.monthly_sub_amount
                      : data?.rent_amount}
                  </p>
                </div>
              </div>

              <div className="payment-box border p-3">
                <h5 className="pb-3 border-bottom text-muted">
                  {" "}
                  {_t("history")}{" "}
                </h5>
                <div className="d-flex flex-column justify-content-between">
                  {haveValue(paymentHistory) && paymentHistory.length > 0
                    ? paymentHistory.map((val, i) => (
                        <div
                          key={val}
                          className={`${
                            i === 0 ? "" : "border-top"
                          } d-flex flex-column py-2`}
                        >
                          <div className="d-flex justify-content-between">
                            <p className="fw-bold">
                              <span className="text-capitalize">
                                {val?.type}
                              </span>
                              -
                              {val.processing_date &&
                              val?.type == constants.PARTIAL_RENT
                                ? ` Since  - ${moment(
                                    val.assigned_property_id.payment_start
                                  ).format("DD/MM/YYYY")}`
                                : moment(val.processing_date).format("MMM")}
                            </p>
                            <p className="price">&#8364;{val?.amount}</p>
                          </div>
                          <div className="d-flex justify-content-between gap-2">
                            <p className="text-muted fs-6">
                              {haveValue(val?.assigned_property_id)
                                ? `${
                                    val?.assigned_property_id?.property_id
                                      ?.street || ""
                                  } ${
                                    val?.assigned_property_id?.property_id
                                      ?.street_number || ""
                                  }, ${
                                    val?.assigned_property_id?.property_id
                                      ?.floor_number || ""
                                  } - ${
                                    val?.assigned_property_id?.property_id
                                      ?.flat_number || ""
                                  }
                                      ${
                                        val?.assigned_property_id?.property_id
                                          ?.province || ""
                                      }, ${
                                    val?.assigned_property_id?.property_id
                                      ?.postcode || ""
                                  }`
                                : `${val?.rental_info_id?.street || ""} ${
                                    val?.rental_info_id?.street_number || ""
                                  }, ${
                                    val?.rental_info_id?.floor_number || ""
                                  } - ${val?.rental_info_id?.flat_number || ""}
                                      ${val?.rental_info_id?.province || ""}, ${
                                    val?.rental_info_id?.postcode || ""
                                  }`}
                            </p>
                            <p className="status">{val?.status}</p>
                          </div>
                          <p className="text-muted mt-1">
                            {new Date(val?.created_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </p>
                        </div>
                      ))
                    : _t("noDetailsFound")}
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-block detail-body">
            <h3 className="payment-text"> {_t("payments")} </h3>

            <div className="d-flex justify-content-between payment-main-container mobile-custom">
              <div className="upcoming-side">
                <div className="upcoming-box">
                  <h4 className="upcoming-text"> {_t("upcoming")} </h4>
                  <table className="upcoming-table">
                    <thead>
                      <tr className="first-row">
                        <th>{_t("date")}</th>
                        <th>{_t("subject")}</th>
                        <th>{_t("amount")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="second-row">
                        <td>
                          {new Date(upcomingDate).toLocaleDateString("en-GB")}
                        </td>
                        <td>{_t(constants.RENT)}</td>
                        <td>
                          {data?.deposit_type == constants.SKOR_DEPOSIT
                            ? +data?.rent_amount + +data?.monthly_sub_amount
                            : data?.rent_amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="upcoming-box">
                  <h4 className="upcoming-text"> {_t("history")} </h4>
                  <table className="upcoming-table">
                    <thead>
                      <tr className="first-row">
                        <th> {_t("date")} </th>
                        <th> {_t("subject")} </th>
                        <th> {_t("amount")} </th>
                        <th> {_t("status")} </th>
                      </tr>
                    </thead>
                    <tbody>
                      {haveValue(paymentHistory) && paymentHistory.length > 0
                        ? paymentHistory.map((val) => (
                            <tr className="second-row">
                              <td>
                                {new Date(val?.created_at).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                              <td>
                                {_t(val?.type)}-
                                {val.processing_date &&
                                val?.type == constants.PARTIAL_RENT
                                  ? ` Since  - ${moment(
                                      val.assigned_property_id.payment_start
                                    ).format("DD/MM/YYYY")}`
                                  : moment(val.processing_date).format("MMM")}
                                <p className="ledger_data">
                                  {haveValue(val?.assigned_property_id)
                                    ? `${
                                        val?.assigned_property_id?.property_id
                                          ?.street || ""
                                      } ${
                                        val?.assigned_property_id?.property_id
                                          ?.street_number || ""
                                      }, ${
                                        val?.assigned_property_id?.property_id
                                          ?.floor_number || ""
                                      } - ${
                                        val?.assigned_property_id?.property_id
                                          ?.flat_number || ""
                                      }
                                      ${
                                        val?.assigned_property_id?.property_id
                                          ?.province || ""
                                      }, ${
                                        val?.assigned_property_id?.property_id
                                          ?.postcode || ""
                                      }`
                                    : `${val?.rental_info_id?.street || ""} ${
                                        val?.rental_info_id?.street_number || ""
                                      }, ${
                                        val?.rental_info_id?.floor_number || ""
                                      } - ${
                                        val?.rental_info_id?.flat_number || ""
                                      }
                                      ${val?.rental_info_id?.province || ""}, ${
                                        val?.rental_info_id?.postcode || ""
                                      }`}
                                </p>
                              </td>
                              <td>{val?.amount}</td>
                              <td>{_t(val?.status)}</td>
                            </tr>
                          ))
                        : _t("noDetailsFound")}
                    </tbody>
                  </table>
                </div>
                {user?.rental_data.type == constants.ASSIGNED_PROPERTY && (
                  <div className="upcoming-box">
                    <h4 className="upcoming-text">
                      {" "}
                      {_t("fullDepositDetails")}{" "}
                    </h4>
                    <table className="upcoming-table">
                      <thead>
                        <tr className="first-row">
                          <th> {_t("amount")} </th>
                          <th> {_t("paidOn")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="second-row">
                          <td className="paidamount-first">
                            {data?.deposit_type == constants.SKOR_DEPOSIT
                              ? data?.rent_amount
                              : data?.deposit_amount}{" "}
                            €
                          </td>
                          <td>
                            {depositHistory?.status == constants.COMPLETED
                              ? new Date(
                                  depositHistory?.processing_date
                                ).toLocaleDateString("en-GB")
                              : _t("pending")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {haveValue(data?.deposit_slip) && (
                      <div className="upload-document">
                        <p>{_t("document")}</p>
                        <Card className="document-card">
                          <div className="videoframe">
                            <iframe
                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${data?.deposit_slip}`}
                              scrolling="no"
                            />
                          </div>
                          <Card.Body>
                            <div className="listing-head">
                              <div className="head-block">
                                <div className="leftbartext">
                                  <p className="pdf-or-else">
                                    {data?.deposit_slip
                                      .split("-")
                                      .slice(1)
                                      .join("-")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="current-side">
                <div className="current-upper">
                  <h5>
                    {" "}
                    {_t("currentBalanceDueOn")}{" "}
                    {new Date(upcomingDate).toLocaleDateString("en-GB")}
                  </h5>

                  <h3> {dueAmount} </h3>
                  <div className="edit-payment-method">
                    <button onClick={navigateRevisitPayment}>
                      {" "}
                      {_t("payNow")}
                    </button>
                  </div>
                </div>
                {haveValue(data) && (
                  <div className="current-down">
                    <h4> {_t("leaseInformation")} </h4>
                    <div>
                      <h6>{_t("moveInAddress")}</h6>
                      <p>
                        {" "}
                        {`${data?.property_id?.street} - ${data?.property_id?.street_number}, ${data?.property_id?.floor_number}-${data?.property_id?.flat_number}`}{" "}
                      </p>
                    </div>
                    <div className="row gap-3">
                      {user?.rental_data.type ==
                        constants.ASSIGNED_PROPERTY && (
                        <>
                          <div className="col-sm-5">
                            <h6> {_t("leaseStartDate")} </h6>
                            <p>
                              {" "}
                              {new Date(data?.payment_start).toLocaleDateString(
                                "en-GB"
                              )}
                            </p>
                          </div>
                          <div className="col-sm-5">
                            <h6> {_t("leaseEndDate")} </h6>
                            <p>
                              {" "}
                              {haveValue(data?.lease_end)
                                ? new Date(data?.lease_end).toLocaleDateString(
                                    "en-GB"
                                  )
                                : _t("open")}
                            </p>
                          </div>
                        </>
                      )}
                      <div className="col-sm-5">
                        <h6> {_t("rent")} </h6>
                        <p> {data?.rent_amount} </p>
                      </div>
                      {user?.rental_data.type ==
                        constants.ASSIGNED_PROPERTY && (
                        <div className="col-sm-5">
                          <h6> {_t("deposit")} </h6>
                          <p> {data?.deposit_amount} </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <h4>{_t("noDetails")}</h4>
      )}
    </>
  );
};

export default PropertyPayment;
