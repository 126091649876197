import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Row, Col, Modal, Accordion, Card } from "react-bootstrap";
import axios from "axios";
import AuthContext from "../../context/auth/AuthContext";
import TenantContext from "../../context/tenant/TenantContext";
import Form from "react-bootstrap/Form";
import ProfileScore from "../../components/Tenant/ProfileScore";
import "react-circular-progressbar/dist/styles.css";
import { sendUniversalMail } from "../../services/sharedService";
import tickNew from "../../assets/img/tick-new.svg";
import exclamation from "../../assets/img/exclamation.svg";
import crossNew from "../../assets/img/cross-new.svg";
import useTranslator from "../../hooks/useTranslator";
import { init } from "onfido-sdk-ui";
import countryList from "country-list";
import countries from "i18n-iso-countries";
import { BsBank2 } from "react-icons/bs";
import {
  convertCurrencyFormat,
  fetchCompanyName,
  haveValue,
} from "../../helper/common";
import { toast } from "react-toastify";
import { FaRotate } from "react-icons/fa6";
import { MdInfoOutline, MdOutlineVerified } from "react-icons/md";
import { useCookies } from "react-cookie";
import AcceptGuarantor from "../../components/AcceptGuarantor";
import AlreadyGuarantorOf from "../../components/AlreadyGuarantorOf";
import AlreadyGuarantorTo from "../../components/AlreadyGuarantorTo";
import SomeoneGuarantor from "../../components/SomeoneGuarantor";
import moment from "moment";
import { VscEye } from "react-icons/vsc";
import { CiBank } from "react-icons/ci";
import constants from "../../Utils/constants";
import OtpVerification from "../../components/OtpVerification/OtpVerification";
import ProfileInfoSection from "../../components/TenantHome/ProfileInfoSection";
import ProfessionalAcademicSection from "../../components/TenantHome/ProfessionalAcademicSection";
import SummarySection from "../../components/TenantHome/SummarySection";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const TenantHome = () => {
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const {
    user,
    loadUser,
    setLoading,
    loadUserTenant,
    setBankLoading,
    setAlgoanLoading,
    updateUserInfo,
  } = authContext;
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [showConfirmGuarantor, setShowConfirmGuarantor] = useState(false);
  const [removeGuarantorModel, setRemoveGuarantorModel] = useState(false);
  const [ident_all, setIdent_all] = useState(false);
  const [alreadyGurantorTo, setAlreadyGurantorTo] = useState(false);
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);
  const [gurantorPresent, setGurantorPresent] = useState(false);
  const [tenantDocsData, setTenantDocsData] = useState(null);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentActiveAccordion, setCurrentActiveAccordion] = useState(0);
  const [zeroDeposit, setZeroDeposit] = useState(false);
  const [ieScore, setIeScore] = useState(0);

  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);
  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const error1 = queryParameters.get("error");
  const docRef = useRef(null);
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {
    if (error1) {
      toast.error(_t("bankDetailsCannotBeFetched"));
    }
    if (account_verification_report_id && transaction_report_id) {
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("userNotAuthorized"));
      } else {
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    await clearPaymentData(userid);
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
  };

  const clearPaymentData = async (tenantid) => {
    return new Promise((resolve) => {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("emailSent"));
      })
      .catch((err) => {
        snackbar.showMessage(_t("errorSendingMail"), err?.message);
        console.log(err);
      });
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    setBankLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
            setAlgoanLoading(true);
            getAnalysisDetails();
            setBankLoading(false);
          } else {
            toast.error(_t("skorCanNotBeCalculated"));
          }
        }
        if (serverResponse.status === "success") {
          toast.success(_t("bankConnectedSuccessfully"));
          loadUser(userType);
        }
        setBankLoading(false);
      })
      .catch((err) => {
        let err_res = err;
        setBankLoading(false);
        //console.log(err_res);
      });
  };

  const countries1 = countryList.getData();
  const getAlpha3Code = (countryName) => {
    const country = countries1.find(
      (c) => c.name?.toLowerCase() === countryName
    );
    return country ? countries.alpha2ToAlpha3(country.code) : "Not found";
  };

  const alpha3ToName = (code) => {
    return countries.getName(code, "en");
  };
  const { userType } = authContext;
  const [showGurantor, setShowGurantor] = useState(false);
  const [guarantorData, setGuarantorData] = useState({
    gurantor_name: "",
    gurantor_email: "",
  });

  const handleShowGurantor = () => setShowGurantor(true);
  const handleHideGurantor = () => {
    setShowGurantor(false);
    setGuarantorData({
      gurantor_name: "",
      gurantor_email: "",
    });
  };

  const tenantContext = useContext(TenantContext);
  const {
    myRentedProperties,
    myPreviouslyRentedProperties,
    onfidoKyc,
    getKycDocs,
    kycuserinfo,
    shareProfile,
    removeGuarantor,
    fetchTenantDocs,
    deleteTenantDoc,
    checkEmailInDeposit,
  } = tenantContext;

  const [error, setError] = useState(false);
  const [offers, setOffers] = useState([]);
  const [scoreError, setScoreError] = useState({});
  const [scoreLoading, setScoreLoading] = useState(false);
  const [Bankshow, BanksetShow] = useState(false);
  const [userKycInfo, setUserKycInfo] = useState({});
  const [recordedSalary, setRecordedSalary] = useState({
    salary: 0,
    matched: 0,
  });
  const [showPhoneVerificationdModal, setShowPhoneVerificationdModal] =
    useState(false);

  //console.log("RECORDED SALARY = ", recordedSalary);
  const [isEmployeementDataSet, setIsEmployeementDataSet] = useState(false);
  const [haveGuarantor, setHaveGuarantor] = useState(false);

  const shareLink = (tenantid) => {
    let sharing_url = `https://${process.env.REACT_APP_URL_NAME}/profile-sharing/${tenantid}`;
    navigator.clipboard.writeText(sharing_url);
    //toast.success("Sharing Link has been copied");
    snackbar.showMessage("Sharing Link has been copied");
  };

  useEffect(() => {
    const user_type = localStorage.getItem("usertype");
    const userId = localStorage.getItem("saveduserid");
    if (haveValue(cookies.requestProfileLink) && user_type == "tenant") {
      if (haveValue(userId)) {
        if (user?.docSigned) {
          shareProfile(userId, cookies.requestProfileLink, "null", "full", {
            shareType: "lLink",
          })
            .then((res) => {
              removeCookie("requestProfileLink", { maxAge: 1800 });
            })
            .catch((err) => {
              //console.log(err)
            });
        } else {
          navigate("/profile-shared?agreement=true");
        }
      }
    }

    if (haveValue(cookies.gurantorFor) && user_type == "tenant") {
      if (
        haveValue(userId) &&
        !haveValue(user?.gurantor_for) &&
        !haveValue(user?.gurantor_id)
      ) {
        setShowConfirmGuarantor(true);
      }
      if (haveValue(user?.gurantor_for)) {
        setAlreadyGurantorTo(true);
      }
      if (haveValue(user?.gurantor_id)) {
        setAlreadyGurantorOf(true);
      }
    }

    loadOffers();
    if (!user?.analysisCompleted && user?.algoanAnalysis) {
      // getAnalysisDetails();
      setScoreLoading(true);
    }

    myRentedProperties(user?._id);
    myPreviouslyRentedProperties(user?._id);

    if (!user?.onfido_kyc_success) {
      getKycReport(user?._id);
    }

    if (user?.employment_status == constants.employed) {
      if (user?.name_of_company && user?.position && user?.type_of_employee) {
        setIsEmployeementDataSet(true);
      }
    }

    if (
      user?.have_gurantor == "no" ||
      user?.have_gurantor == null ||
      user?.haveGuarantor == false
    ) {
      setHaveGuarantor(false);
    } else {
      setHaveGuarantor(true);
    }
    fetchFileData();

    checkEmailInDeposit(user?.email)
      .then((res) => {
        if (haveValue(res.success_res.checkPresent)) {
          setZeroDeposit(false);
        } else {
          const checkLocal = localStorage.getItem("hideDeposit");
          const localDate = new Date(checkLocal);
          const newDate = new Date();
          console.log(checkLocal, localDate, newDate, "check dates");
          if (newDate > localDate) {
            setZeroDeposit(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    checkSalary();
    getkycDetails();
  }, [kycuserinfo]);

  useEffect(() => {
    checkAllMatches();
    calculateIeScore();
  }, [userKycInfo, user]);

  const calculateIeScore = () => {
    var totalScore = (user?.paymentScores?.creditScore?.value || 0) / 2;

    if (user?.ie_verified && user?.is_university) {
      if (totalScore >= 600) {
        totalScore += 200;
      } else {
        totalScore += 400;
      }
    }
    if (totalScore > 850) {
      totalScore = 850;
    }
    setIeScore(totalScore);
  };

  const checkSalary = () => {
    if (haveValue(user?.gurantor_id)) {
      if (user?.gurantor_id?.algoanIncome) {
        const str = String(user?.gurantor_id?.algoanIncome?.monthlyAmount);
        const numericStr = str.replace(/,/g, ""); // Removes only commas
        const monthlyIncome = Math.round(parseFloat(numericStr));

        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user?.gurantor_id?.current_annual_income)
            ? user?.gurantor_id?.current_annual_income
            : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
    } else {
      console.log(user.algoanIncome, "NAMAN CHECK HERE");
      if (user.algoanIncome) {
        let monthlyIncome = parseInt(user.algoanIncome?.monthlyAmount);
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user.current_annual_income) ? user.current_annual_income : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
    }
  };

  useEffect(() => {
    getkycDetails();
  }, [user]);

  const getkycDetails = () => {
    let userid = localStorage.getItem("saveduserid");
    if (user?.onfido_kyc_success) {
      //// console.log("kyc has been done");
      loadKycReport(userid);
    } else {
      //// console.log("kyc has  been done but result has not been fetched");
      getKycReport(userid);
    }
  };

  const loadKycReport = async (tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getkycreport/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        //// console.log(res);
        //toast.success("KYC Report Fetched");
        setUserKycInfo(res.data.kycuser);
      })
      .catch((err) => {
        //console.log(err);
        //toast.error("Kyc Report Not Fetched")
      });
  };

  const getKycReport = async (tenantid) => {
    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/getkycstatus/${tenantid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          ////  console.log(res);
          if (res.data.status === "success") {
            //toast.success("KYC Report Fetched")
            setUserKycInfo(res.data.kycuser);
            clearInterval(myInterval);
          } else {
            clearInterval(myInterval);
          }
        })
        .catch((err) => {
          //console.log(err);
          //toast.error("Kyc Report Not Fetched")
          clearInterval(myInterval);
        });
    }, 5000);
  };

  const getAnalysisDetails = async (req, res) => {
    let userid = localStorage.getItem("saveduserid");

    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          //// console.log(res);
          setAlgoanLoading(false);
          setScoreLoading(false);
          clearInterval(myInterval);
          //// console.log("interval stopped");
          loadUserTenant(userType);
        })
        .catch((err) => {
          setScoreLoading(false);
          setAlgoanLoading(false);
          //// console.log(err.response.data);
          setScoreError(err.response.data);
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };

  const loadOffers = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/offers/`;
    await axios
      .get(url, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        setLoading(false);
        setOffers(res.data.offers);
        //// console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        //console.log(err);
      });
  };

  const checkSkorStatus = (user) => {
    const skor = haveValue(user?.gurantor_id?.paymentScores?.creditScore?.value)
      ? user?.gurantor_id?.paymentScores?.creditScore?.value
      : user?.ie_verified && user?.is_university
      ? ieScore
      : user?.paymentScores?.creditScore?.value;
    if (skor <= 200) {
      return <p className="UnstableText">{_t("unstable")}</p>;
    } else if (skor >= 201 && skor <= 400) {
      return <p className="VariableText">{_t("variable")}</p>;
    } else if (skor >= 401 && skor <= 600) {
      return <p className="StableText">{_t("stable")}</p>;
    } else if (skor >= 601 && skor <= 800) {
      return <p className="ReliableText">{_t("reliable")}</p>;
    } else if (skor >= 800) {
      return <p className="ExtremelyText">{_t("extremelyReliable")}</p>;
    }
  };

  const BankhandleClose = () => BanksetShow(false);
  const bankhandleShow = () => BanksetShow(true);
  const [countryCodeError, setCountryCodeError] = useState("");

  const [tink_countryCode, setTink_countryCode] = useState("");
  const changeCountryCode = (e) => {
    setTink_countryCode(e.target.value);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError("pleaseSelectCountryCode");
      return;
    } else {
      setCountryCodeError("");
    }
    var lang = "en_US";
    if (localStorage.getItem("i18nextLng") == "es") {
      lang = "es_ES";
    }
    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_URL_NAME}%2Fhome&market=${tink_countryCode}&locale=${lang}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    window.open(tinkUrlLiveFront, "_self");
  };

  const handleGurantorModal = () => {
    handleShowGurantor();
  };

  const handleSubmitGurantor = async (e) => {
    e.preventDefault();
    let userid = localStorage.getItem("saveduserid");
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/gurantor/${userid}`,
        guarantorData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setGurantorPresent(true);
          setAlreadyGurantorOf(true);
          handleHideGurantor();
        } else {
          toast.success(_t("guarantorAdded"));
          loadUser(userType);
          sendMail(guarantorData?.gurantor_email, "score");
          handleHideGurantor();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error);
      });
    //console.log("SUBMITTED")
  };

  const getEmploymentStatus = () => {
    if (user?.employment_status == constants.employed) {
      return _t("employed");
    }
    if (user?.employment_status == constants.self_employed) {
      return _t("selfEmployed");
    }
    if (user?.employment_status == constants.student) {
      return _t("student");
    }
    if (user?.employment_status == constants.unemployed) {
      return _t("unemployed");
    }
  };

  const checkAllMatches = async () => {
    //console.log("CHECKALLMATCHES CALLED!")
    let fname = user?.fname.toString();
    let lname = user?.lname.toString();
    let newName = fname.split(" ");
    let newLname = lname.split(" ");

    let temp_fname = "";
    for (let i = 0; i < newName.length; i++) {
      newName[i] = newName[i]?.toLowerCase();
      temp_fname = temp_fname + newName[i];
    }

    let temp_lname = "";
    for (let i = 0; i < newLname.length; i++) {
      newLname[i] = newLname[i]?.toLowerCase();
      temp_lname = temp_lname + newLname[i];
    }
    let kyc_fullname = "";
    if (
      (userKycInfo?.onfido_doc_data?.first_name,
      userKycInfo?.onfido_doc_data?.last_name)
    ) {
      let kyc_fname = userKycInfo?.onfido_doc_data?.first_name;
      let kyc_lname = userKycInfo?.onfido_doc_data?.last_name;

      let kycName = kyc_fname.split(" ");
      let kycLname = kyc_lname.split(" ");

      let temp_fname1 = "";
      for (let i = 0; i < newName.length; i++) {
        if (kycName[i]) {
          kycName[i] = kycName[i]?.toLowerCase();
          temp_fname1 = temp_fname1 + kycName[i];
        }
      }

      let temp_lname1 = "";
      for (let i = 0; i < newLname.length; i++) {
        if (kycLname[i]) {
          kycLname[i] = kycLname[i]?.toLowerCase();
          temp_lname1 = temp_lname1 + kycLname[i];
        }
      }

      kyc_fullname = temp_fname1 + temp_lname1;
    }

    if (user?.onfido_kyc_success == false) {
      setIdent_all(false);
      return false;
    }

    setIdent_all(true);
    return true;
  };

  const handleRemoveGuarantor = () => {
    setRemoveGuarantorModel(true);
  };

  const hideRemoveGuarantor = () => {
    setRemoveGuarantorModel(false);
  };

  const submitRemoveGuarantor = async (e) => {
    e.preventDefault();
    await removeGuarantor(user?.gurantor_for?._id, user?._id);
    hideRemoveGuarantor();
    loadUser(userType);
  };

  const fetchFileData = async () => {
    let userid = localStorage.getItem("saveduserid");
    const res = await fetchTenantDocs(userid);
    console.log(res);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleDeleteDoc = async (doc_id) => {
    setLoading(true);
    try {
      const res = await deleteTenantDoc(doc_id);
      await fetchFileData();
      setLoading(false);
      setDeleteId(null);
      setDeleteDocumentModal(false);
      if (res != false) toast.success(_t("documentDeletedSuccessfully"));
    } catch (err) {
      setLoading(false);
      toast.error(_t("unableToDeleteDocument"));
    }
  };

  const handleHideDocument = () => {
    setDeleteDocumentModal(false);
    setDeleteId(null);
  };

  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const handleAccordionSelect = (eventKey) => {
    console.log(currentActiveAccordion);
    setCurrentActiveAccordion(parseInt(eventKey));
  };

  return (
    <>
      <div id="onfido-mount"></div>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="28.33%"
              style={{ stopColor: "#F5DD01", stopOpacity: 1 }}
            />
            <stop
              offset="70.82%"
              style={{ stopColor: "#99DE04", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
      </svg>
      <div className="landlord-profile-edit">
        {zeroDeposit && <></>}
        <Accordion
          className="primaryaccordion d-md-grid mobile-primary-accordaion"
          defaultActiveKey={currentActiveAccordion.toString()}
          onSelect={handleAccordionSelect}
        >
          <Accordion.Item eventKey="0" id="divToPrint">
            <div className="withdownloadbtn">
              <Accordion.Header className="primaryaccordion-header setaccordionhead sdv">
                <div className="d-flex justify-content-between w-100 score_profile_div">
                  <span className="maintitle">{_t("skorAndDetails")}</span>
                  {currentActiveAccordion.toString() != "0" && (
                    <span className="accordion_score">
                      {haveValue(user?.gurantor_id) &&
                      haveValue(user?.gurantor_id?.paymentScores)
                        ? haveValue(
                            user?.gurantor_id?.paymentScores?.creditScore?.value
                          )
                          ? user?.gurantor_id?.paymentScores?.creditScore?.value
                          : 0
                        : haveValue(user?.paymentScores?.creditScore?.value)
                        ? user?.ie_verified && user?.is_university
                          ? ieScore
                          : +user?.paymentScores?.creditScore?.value
                        : user?.ie_verified && user?.is_university
                        ? ieScore
                        : +0}
                    </span>
                  )}
                </div>
              </Accordion.Header>
              {currentActiveAccordion.toString() == "0" && (
                <button
                  className="download_profile_btn downloadProfileBtn"
                  id="donwloadProfileBtn"
                  onClick={(e) =>
                    window.open(
                      `https://${process.env.REACT_APP_URL_NAME}/profile/preview/${user?._id}?user_type=tenant`,
                      "_blank"
                    )
                  }
                >
                  <svg
                    width="3.96vw"
                    height="3.96vw"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="27" cy="27" r="27" fill="#A3EB64" />
                    <path
                      d="M22.0907 17.1836C20.7369 17.1836 19.6362 18.2843 19.6362 19.6381V23.32H22.0907V19.6381H30.7852L31.9089 20.7619V23.32H34.3635V20.7619C34.3635 20.1099 34.1065 19.4847 33.6463 19.0245L32.5225 17.9008C32.0623 17.4406 31.4372 17.1836 30.7852 17.1836H22.0907ZM31.9089 30.6836V34.3654H22.0907V30.6836H31.9089ZM34.3635 31.9109H35.5907C36.2696 31.9109 36.818 31.3624 36.818 30.6836V27.0018C36.818 25.6479 35.7173 24.5472 34.3635 24.5472H19.6362C18.2824 24.5472 17.1816 25.6479 17.1816 27.0018V30.6836C17.1816 31.3624 17.7301 31.9109 18.4089 31.9109H19.6362V34.3654C19.6362 35.7192 20.7369 36.82 22.0907 36.82H31.9089C33.2627 36.82 34.3635 35.7192 34.3635 34.3654V31.9109ZM33.7498 26.695C33.9939 26.695 34.2281 26.7919 34.4007 26.9646C34.5733 27.1372 34.6703 27.3713 34.6703 27.6154C34.6703 27.8595 34.5733 28.0937 34.4007 28.2663C34.2281 28.4389 33.9939 28.5359 33.7498 28.5359C33.5057 28.5359 33.2716 28.4389 33.099 28.2663C32.9263 28.0937 32.8294 27.8595 32.8294 27.6154C32.8294 27.3713 32.9263 27.1372 33.099 26.9646C33.2716 26.7919 33.5057 26.695 33.7498 26.695Z"
                      fill="#253837"
                    />
                  </svg>
                </button>
              )}
            </div>
            <Accordion.Body className="primaryaccordion-body paddingTop0">
              <div className="tabs-box-pattern">
                <div id="main-content">
                  <div id="page-container" className="tab-content">
                    <div
                      id="home"
                      className="tab-pane active padding-full-zero"
                    >
                      <div className="tabs-item d-block">
                        <div className="userscrollprofile-topbar mobile-user-profile">
                          {
                            <Row className="mx-0">
                              {haveValue(user?.gurantor_id) &&
                              haveValue(user?.gurantor_id?.paymentScores) ? (
                                user?.gurantor_id?.paymentScores && (
                                  <Col xl={6} className="mb-3 ">
                                    <div className="border-pd accordion-tile">
                                      <h4>{_t("profile")}</h4>
                                      <div className="process-bar-wappers process2 ">
                                        {user?.gurantor_id?.paymentScores && (
                                          <>
                                            <div className="gradient-progress-block">
                                              <ProfileScore
                                                creditScore={
                                                  haveValue(
                                                    user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                  )
                                                    ? user?.gurantor_id
                                                        ?.paymentScores
                                                        ?.creditScore?.value <
                                                      800
                                                      ? user?.gurantor_id
                                                          ?.paymentScores
                                                          ?.creditScore?.value
                                                      : 800
                                                    : user?.ie_verified &&
                                                      user?.is_university
                                                    ? ieScore < 800
                                                      ? ieScore
                                                      : 800
                                                    : user?.paymentScores
                                                        ?.creditScore?.value <
                                                      800
                                                    ? user?.paymentScores
                                                        ?.creditScore?.value
                                                    : 800
                                                }
                                                rentScore={0}
                                                showRental={false}
                                              >
                                                <h1>
                                                  {haveValue(
                                                    user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                  )
                                                    ? user?.gurantor_id
                                                        ?.paymentScores
                                                        ?.creditScore?.value <
                                                      800
                                                      ? user?.gurantor_id
                                                          ?.paymentScores
                                                          ?.creditScore?.value
                                                      : 800
                                                    : user?.ie_verified &&
                                                      user?.is_university
                                                    ? ieScore < 800
                                                      ? ieScore
                                                      : 800
                                                    : user?.paymentScores
                                                        ?.creditScore?.value <
                                                      800
                                                    ? user?.paymentScores
                                                        ?.creditScore?.value
                                                    : 800}
                                                </h1>
                                                {/* <p>Reliable</p> */}
                                                {checkSkorStatus(user)}
                                              </ProfileScore>
                                            </div>

                                            <div className="name-box">
                                              <h3 className="score-name">
                                                {
                                                  user?.gurantor_id?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.accounts[0]?.holderName
                                                }
                                                {"*"}
                                              </h3>
                                              <div className="expiry-skor-date">
                                                <span>
                                                  {_t("createdOn")}:{" "}
                                                  {moment(
                                                    haveValue(
                                                      user?.bankVerificationDate
                                                    )
                                                      ? user?.bankVerificationDate
                                                      : user?.created_at
                                                  ).format("DD-MM-YYYY")}
                                                </span>
                                                <span>
                                                  {_t("validTill")}:{" "}
                                                  {moment(
                                                    haveValue(
                                                      user?.bankVerificationDate
                                                    )
                                                      ? user?.bankVerificationDate
                                                      : user?.created_at
                                                  )
                                                    .add(2, "months")
                                                    .format("DD-MM-YYYY")}
                                                </span>
                                              </div>
                                              <ul className="score-textgray">
                                                {user?.ie_verified &&
                                                user?.is_university &&
                                                !haveValue(
                                                  user?.gurantor_id
                                                    ?.paymentScores?.creditScore
                                                    ?.value
                                                ) &&
                                                !haveValue(
                                                  user?.paymentScores
                                                    ?.creditScore?.value
                                                ) ? (
                                                  <span>
                                                    *
                                                    {_t("skorBasedOnIEProfile")}{" "}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    *
                                                    {_t(
                                                      "bankAccountUsedForScoring"
                                                    )}{" "}
                                                  </span>
                                                )}
                                              </ul>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                )
                              ) : user?.paymentScores ||
                                (user?.ie_verified && user?.is_university) ? (
                                <Col xl={6} className="mb-3 mobile-profile">
                                  <div className="border-pd accordion-tile">
                                    <h4>{_t("profile")}</h4>
                                    <div className="process-bar-wappers process2 ">
                                      {(user?.paymentScores ||
                                        (user?.ie_verified &&
                                          user?.is_university)) && (
                                        <>
                                          <ProfileScore
                                            creditScore={
                                              haveValue(
                                                user?.gurantor_id?.paymentScores
                                                  ?.creditScore?.value
                                              )
                                                ? user?.gurantor_id
                                                    ?.paymentScores?.creditScore
                                                    ?.value < 800
                                                  ? user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                  : 800
                                                : user?.ie_verified &&
                                                  user?.is_university
                                                ? ieScore < 800
                                                  ? ieScore
                                                  : 800
                                                : user?.paymentScores
                                                    ?.creditScore?.value < 800
                                                ? user?.paymentScores
                                                    ?.creditScore?.value
                                                : 800
                                            }
                                            rentScore={0}
                                            showRental={false}
                                          >
                                            <h1>
                                              {haveValue(
                                                user?.gurantor_id?.paymentScores
                                                  ?.creditScore?.value
                                              )
                                                ? user?.gurantor_id
                                                    ?.paymentScores?.creditScore
                                                    ?.value < 800
                                                  ? user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                  : 800
                                                : user?.ie_verified &&
                                                  user?.is_university
                                                ? ieScore < 800
                                                  ? ieScore
                                                  : 800
                                                : user?.paymentScores
                                                    ?.creditScore?.value < 800
                                                ? user?.paymentScores
                                                    ?.creditScore?.value
                                                : 800}
                                            </h1>
                                            {/* <p>Reliable</p> */}
                                            {checkSkorStatus(user)}
                                          </ProfileScore>
                                          <div className="name-box">
                                            <h3 className="score-name">
                                              {haveValue(
                                                user?.gurantor_id?.paymentScores
                                                  ?.creditScore?.value
                                              )
                                                ? user?.gurantor_id?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.accounts[0]?.holderName
                                                : haveValue(
                                                    user?.kyc_details
                                                      ?.userDataByProvider[0]
                                                      ?.accounts[0]?.holderName
                                                  )
                                                ? user?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.accounts[0]?.holderName
                                                : `${user?.ie_details_id?.fname} ${user?.ie_details_id?.lname}`}
                                              {"*"}
                                            </h3>
                                            <div className="expiry-skor-date">
                                              <span>
                                                {_t("createdOn")}:{" "}
                                                {moment(
                                                  haveValue(
                                                    user?.bankVerificationDate
                                                  )
                                                    ? user?.bankVerificationDate
                                                    : user?.created_at
                                                ).format("DD-MM-YYYY")}
                                              </span>
                                              <span>
                                                {_t("validTill")}:{" "}
                                                {moment(
                                                  haveValue(
                                                    user?.bankVerificationDate
                                                  )
                                                    ? user?.bankVerificationDate
                                                    : user?.created_at
                                                )
                                                  .add(2, "months")
                                                  .format("DD-MM-YYYY")}
                                              </span>
                                            </div>
                                            <ul className="score-textgray">
                                              {user?.ie_verified &&
                                              user?.is_university &&
                                              !haveValue(
                                                user?.gurantor_id?.paymentScores
                                                  ?.creditScore?.value
                                              ) &&
                                              !haveValue(
                                                user?.paymentScores?.creditScore
                                                  ?.value
                                              ) ? (
                                                <span>
                                                  *{_t("skorBasedOnIEProfile")}{" "}
                                                </span>
                                              ) : (
                                                <span>
                                                  *
                                                  {_t(
                                                    "bankAccountUsedForScoring"
                                                  )}{" "}
                                                </span>
                                              )}
                                            </ul>
                                          </div>
                                          <div className="scorebtnbar">
                                            <button
                                              className="lleftbarbtn btnorangetextonly"
                                              type="button"
                                              onClick={bankhandleShow}
                                            >
                                              <FaRotate />
                                              {_t("regenerateSkor")}{" "}
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                <Col xl={6} className="mb-3 ">
                                  <div className="border-pd accordion-tile">
                                    <h4>{_t("connectBankOrAddGuarantor")}</h4>
                                    <div className="process-bar-wappers process2 ">
                                      <>
                                        <div className="vreify-box">
                                          <div className="verify-detail"></div>
                                          <div className="verify-btn">
                                            <button
                                              className="btn btnorange w-100"
                                              onClick={bankhandleShow}
                                              type="button"
                                            >
                                              <CiBank /> {_t("connectBank")}
                                            </button>
                                          </div>
                                          {haveValue(user?.gurantor_email) ? (
                                            <div className="whenaddgurantor">
                                              <h3>
                                                <svg
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM5.6 10.5L2.1 7L3.087 6.013L5.6 8.519L10.913 3.206L11.9 4.2L5.6 10.5Z"
                                                    fill="#5CBA5F"
                                                  />
                                                </svg>
                                                {_t("guarantorAdded")}
                                              </h3>
                                              {haveValue(
                                                user?.gurantor_id
                                              ) ? null : (
                                                <h5>
                                                  {_t(
                                                    "waitingForGuarantorToAcceptYourRequest"
                                                  )}
                                                </h5>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="Guarantorbar">
                                              <div className="divider">
                                                {_t("oR")}
                                              </div>
                                              <button
                                                className="btn btnoutline"
                                                onClick={handleGurantorModal}
                                              >
                                                {" "}
                                                {_t("addGuarantor")}
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </Col>
                              )}
                              {}

                              <SummarySection
                                user={user}
                                showActionPrompts={true}
                                ident_all={ident_all}
                                recordedSalary={recordedSalary}
                                bankhandleShow={bankhandleShow}
                                haveGuarantor={haveGuarantor}
                              />

                              <ProfileInfoSection
                                user={user}
                                alpha3ToName={alpha3ToName}
                                userKycInfo={userKycInfo}
                                setShowPhoneVerificationdModal={
                                  setShowPhoneVerificationdModal
                                }
                                handleRemoveGuarantor={handleRemoveGuarantor}
                                handleGurantorModal={handleGurantorModal}
                                showActionPrompts={true}
                              />

                              <ProfessionalAcademicSection
                                user={user}
                                recordedSalary={recordedSalary}
                                showActionPrompts={true}
                              />
                            </Row>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="1"
            ref={docRef}
            id="accordion_item_1 "
            className="mobile-document-accordion"
          >
            <Accordion.Header className="primaryaccordion-header setaccordionhead sdv ">
              <div className="d-flex justify-content-between w-100">
                <span className="maintitle">
                  {_t("documents")}{" "}
                  <label className="">
                    <ul className="infodorpdown summarydropdum position-relative">
                      <li>
                        <button className="iconinfo guarantor_tooltip">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>{_t("pdfFilesOnly")}</li>
                        <li>{_t("eachFileSizeTenMBMax")}</li>
                        <li>{_t("tenDocumentsMax")}</li>
                      </ul>
                    </ul>
                  </label>
                </span>
                {currentActiveAccordion.toString() != "1" && (
                  <span className="submain">
                    {Array.isArray(tenantDocsData)
                      ? `${tenantDocsData.length} ${_t("documents")}`
                      : "  "}
                  </span>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body className="primaryaccordion-body">
              <Row className="card-listing">
                {tenantDocsData?.map((eachData, index) => (
                  <Col md={3} key={eachData?._id}>
                    <Card>
                      <div className="videoframe">
                        <iframe
                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                          scrolling="no"
                        />
                      </div>
                      <Card.Body className="mobile-pdf-card-box">
                        <div className="listing-head">
                          <div className="head-block">
                            <div
                              className="leftbartext"
                              style={{ maxWidth: "85%" }}
                            >
                              <h5 style={{ paddingTop: "0px" }}>
                                {_t(eachData?.type, "fixed")}
                              </h5>
                              <p>
                                {eachData?.file_name
                                  .split("-")
                                  .slice(1)
                                  .join("-")}
                              </p>
                            </div>
                            <VscEye
                              onClick={(e) =>
                                handlePreviewDocument(
                                  `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <div className="listing-foot">
                          <span>
                            {moment(eachData?.created_at).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </Card.Body>
                      {eachData?.from == "clave" ? (
                        // <img
                        //   className="upload_doc_icons"
                        //   src="/images/downloaded_clave.svg"
                        //   alt=""
                        // />
                        <label className="doc-uploaded-from">
                          {_t("clavePin")}
                        </label>
                      ) : (
                        <label className="doc-uploaded-from">
                          {_t("uploaded")}
                        </label>
                        // <img
                        //   className="upload_doc_icons"
                        //   src="/images/downloaded_upload.svg"
                        //   alt=""
                        // />
                      )}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <Modal
        show={Bankshow}
        onHide={BankhandleClose}
        centered
        className="newmodalui connectbankmodal"
        size="lg"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div className="row">
            <div className="col-sm-6 p-2 bankimagediv">
              <p>
                {_t(
                  "youWillNowBeRedirectedToConnectYourBankAccountThroughOurPartnerTinkPoweredByVisa"
                )}
              </p>
              <p>
                {_t(
                  "itsImportantForYouToKnowThatWeDoNotHaveDirectAccessToYourBankLoginDetailsThisProcessIsDesignedToMaintainYourCredentialsEncriptedFromSkorOrAnyOtherThirdParty"
                )}
              </p>
              <img src="../images/connect_bank.svg" alt="bank_logo" />
              <p>
                {_t(
                  "weAssureYouOfTheUtmostPrivacyAndSecurityOfYourFinancialData"
                )}
              </p>
            </div>
            <div className="col-sm-6 p-2 connectbankdiv">
              <h3>{_t("connectBank")}</h3>
              <Form className="loginform">
                <div className="auth-form">
                  <div className="forminput row100">
                    <label className="forminput">{_t("country")}</label>
                    <select
                      className="form-control"
                      onChange={changeCountryCode}
                    >
                      <option value="">{_t("selectYourCountry")}</option>
                      <option value="BE">{_t("belgium")}</option>
                      <option value="DE">{_t("germany")}</option>
                      <option value="DK">{_t("denmark")}</option>
                      <option value="EE">{_t("estonia")}</option>
                      <option value="ES">{_t("spain")}</option>
                      <option value="FI">{_t("finland")}</option>
                      <option value="FR">{_t("france")}</option>
                      <option value="GB">{_t("unitedKingdom")}</option>
                      <option value="IT">{_t("italy")}</option>
                      <option value="LT">{_t("lithuania")}</option>
                      <option value="LV">{_t("latvia")}</option>
                      <option value="NL">{_t("netherlands")}</option>
                      <option value="NO">{_t("norway")}</option>
                      <option value="PL">{_t("poland")}</option>
                      <option value="PT">{_t("portugal")}</option>
                      <option value="SE">{_t("sweden")}</option>
                    </select>
                  </div>
                  <div className="forminput row100">
                    <label className="forminput">{_t("connectBank")}</label>

                    <div className="bankname" onClick={connectToBank}>
                      <BsBank2 />
                      {_t("connectBank")}
                    </div>
                    <span className="addRedColor">{countryCodeError}</span>
                  </div>
                </div>
              </Form>
              <div className="bank-btm">
                {_t("shouldYouHaveAnyConcernsOrQuestionsPlease")}
                <a
                  href={undefined}
                  onClick={(e) => navigate("/bank")}
                  className="btnlink"
                >
                  {_t("readHere")}
                </a>
                {_t("orContactUsAt")}
                <a href="mailTo:info@skortorent.com" className="btnlink">
                  info@skortorent.com.
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showGurantor}
        onHide={handleHideGurantor}
        centered
        className="newmodalui"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("addGuarantor")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitGurantor}>
            <div className="auth-form">
              <div className="forminput row100">
                <label className="forminput">{_t("nameOfGuarantor")}</label>
                <input
                  type="text"
                  name="gurantor_name"
                  placeholder={_t("nameOfGuarantor")}
                  onChange={(e) =>
                    setGuarantorData((prevData) => ({
                      ...prevData,
                      gurantor_name: e.target.value,
                    }))
                  }
                  value={guarantorData.gurantor_name}
                />
              </div>
              <div className="forminput row100">
                <label className="forminput">{_t("emailOfGuarantor")}</label>
                <input
                  type="text"
                  name="gurantor_email"
                  placeholder={_t("emailOfGuarantor")}
                  onChange={(e) =>
                    setGuarantorData((prevData) => ({
                      ...prevData,
                      gurantor_email: e.target.value?.toLowerCase(),
                    }))
                  }
                  value={guarantorData.gurantor_email}
                />
              </div>
              <div className="btngroup row100 gridbar">
                <button
                  className="btn btnoutline"
                  type="button"
                  onClick={handleHideGurantor}
                >
                  {_t("cancel")}
                </button>
                <button
                  className="btn btnorange"
                  type="submit"
                  disabled={
                    haveValue(guarantorData.gurantor_name) &&
                    haveValue(guarantorData.gurantor_email)
                      ? false
                      : true
                  }
                >
                  {_t("submit")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showConfirmGuarantor && (
        <AcceptGuarantor
          setShowConfirmGuarantor={setShowConfirmGuarantor}
          showConfirmGuarantor={showConfirmGuarantor}
        />
      )}
      {alreadyGurantorOf && gurantorPresent == false && (
        <AlreadyGuarantorOf
          alreadyGurantorOf={alreadyGurantorOf}
          setAlreadyGurantorOf={setAlreadyGurantorOf}
          gurantorPresent={gurantorPresent}
          setGurantorPresent={setGurantorPresent}
        />
      )}
      {alreadyGurantorOf && gurantorPresent == true && (
        <SomeoneGuarantor
          alreadyGurantorOf={alreadyGurantorOf}
          setAlreadyGurantorOf={setAlreadyGurantorOf}
          gurantorPresent={gurantorPresent}
          setGurantorPresent={setGurantorPresent}
        />
      )}
      {alreadyGurantorTo && (
        <AlreadyGuarantorTo
          alreadyGurantorTo={alreadyGurantorTo}
          setAlreadyGurantorTo={setAlreadyGurantorTo}
          gurantor_details={user?.gurantor_for}
        />
      )}
      {removeGuarantorModel == true && (
        <Modal
          show={removeGuarantorModel}
          onHide={hideRemoveGuarantor}
          centered
          className="newmodalui asguarantor modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {_t("youAreGuarantorOf")}
              {`${user?.gurantor_for?.fname} ${user?.gurantor_for?.lname}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={submitRemoveGuarantor}>
              <div className="auth-form">
                {/* <div className="formtile">
                  <h4>{`${user?.gurantor_for?.fname} ${user?.gurantor_for?.lname}`}<span> {_t("Basic Profile")}</span></h4>
                </div> */}
                <div className="row">
                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="forminput">{_t("name")}:</label>
                    <p>
                      {user?.gurantor_for?.fname} {user?.gurantor_for?.lname}
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="forminput">{_t("nationality")}:</label>
                    <p>{_t(user?.gurantor_for?.nationality, "fixed")}</p>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <label className="forminput">{_t("phoneNumber")}:</label>
                    <p>
                      {haveValue(user?.gurantor_for?.phone_number)
                        ? user?.gurantor_for?.phone_number
                        : _t("noInfo")}
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <label className="forminput">{_t("email")}:</label>
                    <p>{user?.gurantor_for?.email}</p>
                  </div>
                </div>
              </div>
              <div className="row100">
                <button className="btn-reject" type="submit">
                  {_t("removeYourselfAsAGuarantor")}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={deleteDocumentModal}
        className="metermodal"
        centered
        onHide={handleHideDocument}
      >
        <Modal.Body>
          <h4>
            {_t(
              "areYouSureYouWantToDeleteThisDocumentPleaseNoteThatThisActionCannotBeUndone"
            )}
          </h4>
          <div className="auth-form">
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={(e) => {
                  handleHideDocument();
                }}
              >
                {_t("no")}
              </button>
              <button
                className="btn btngreen"
                onClick={(e) => handleDeleteDoc(deleteId)}
              >
                {_t("yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showPhoneVerificationdModal} className="metermodal" centered>
        <Modal.Body className="step-form-main-wrapper modal-wrapper">
          <div className="steps-wrapper">
            <OtpVerification
              label=""
              phone={user?.phone_number || ""}
              onCancel={() => {
                setShowPhoneVerificationdModal(false);
              }}
              onSubmit={(phoneNumber) => {
                if (phoneNumber) {
                  setShowPhoneVerificationdModal(false);
                  updateUserInfo({
                    phone_number: phoneNumber,
                    phone_number_verified: true,
                  })
                    .then((res) => {
                      loadUser(userType);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  setShowPhoneVerificationdModal(false);
                }
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantHome;
