import { useContext } from "react";
import useTranslator from "../../hooks/useTranslator";
import LandLordContext from "../../context/landlord/LandLordContext";

const LandlordBlurApprove = (props) => {
  const { landlord_user } = props;
  const { _t } = useTranslator();
  const landLordContext = useContext(LandLordContext);
  const { sendAdminApprovalRequest } = landLordContext;

  const handleSentApprovalRequest = () => {
    sendAdminApprovalRequest(landlord_user?._id);
  };

  return (
    <>
      <div className="blur-info-button">
        <p>{_t("toViewProfileGetApprovedFromSkorAdmin")}</p>
        <button className="btn btngreen" onClick={handleSentApprovalRequest}>
          {_t("sendRequestToGetApproved")}
        </button>
      </div>
    </>
  );
};

export default LandlordBlurApprove;
