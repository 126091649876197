import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { withBackendUrl } from "../helper/common";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    debug: true,
    returnNull: false,
    lng:
      localStorage.getItem("i18nextLng") ??
      (window.location.host === process.env.REACT_APP_MACC_URL_NAME
        ? "es"
        : "en-us"),
    fallbackLng: "en-us",
    load: "currentOnly",
    lowerCaseLng: true,
    ns: ["translation", "fixed"], // Specify the namespaces you are using
    defaultNS: "translation",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: withBackendUrl("/locales/{{lng}}/{{ns}}"),
      addPath: withBackendUrl("/locales/add/{{lng}}/{{ns}}"),
      crossDomain: true,
      reloadInterval: false,
      allowMultiLoading: true,
      parse: (data, url) => {
        let langData = JSON.parse(data);
        if (Object.keys(langData).length > 0) {
          let firstKey = Object.keys(langData)[0];
          langData = langData[firstKey];
          if (Object.keys(langData).length > 0) {
            let secKey = Object.keys(langData)[0];
            langData = langData[secKey];
          }
        }
        return langData;
      },
    },
    partialBundledLanguages: true,
    saveMissing: true,
  });

export default i18n;
